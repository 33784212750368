import { useContext, useEffect, useReducer, useState } from 'react';
import './ValidationSettings.less';
import { validationSettingsReducer } from './utils/validationSettingsReducer';
import SettingsHeader from './SettingsHeader/SettingsHeader';
import FieldSettingsTable from './FieldSettingsTable/FieldSettingsTable';
import { IReceiptType } from '../../interfaces/Receipt';
import { ContextApp } from '../../contexts/ContextApp';
import {
  FieldAttributeTenant,
  Schema,
} from '../../interfaces/ValidationSchema';
import { getSchemaTree } from './utils/getSchemaTree';
import { getCustomFieldAttributeTenants } from './utils/getCustomFieldAttributeTenants';
import { BusinessSettingsState, SchemaState } from './interface';
import { BusinessSettingsActionTypes, SchemaActionTypes } from './enum';
import useDataValidationService from './utils/useDataValidationService';
import RestoreAllModal from './RestoreAllModal/RestoreAllModal';
import { notificationContext } from '../../contexts/NotificationContext';
import { businessSettingsReducer } from './utils/businessSettingsReducer';
import BusinessSettingsTable from './BusinessSettingsTable/BusinessSettingsTable';
import { BusinessValidation } from '../../interfaces/BusinessValidation';
import { getCustomBusinessSettings } from './utils/getCustomBusinessSettings';
import { ReceiptTypeDocumentType } from '../../interfaces/ReceiptTypeDocumentType';
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning/useUnsavedChangesWarning';

// TODO: intentar unificar los modales a un solo componente

const ValidationSettings = () => {
  const { selectedTenantId, t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const [receiptTypes, setReceiptTypes] = useState<IReceiptType[] | null>(null);
  const [currentReceiptType, setCurrentReceiptType] =
    useState<IReceiptType | null>(null);
  const [currentDocumentType, setCurrentDocumentType] =
    useState<ReceiptTypeDocumentType | null>(null);
  const [restoreAllModalStatus, setRestoreAllModalStatus] = useState(false);

  // Debido a que los dispatchers de useReducer son asincronos, se usa un estado local para controlar el guardado
  const [shouldSave, setShouldSave] = useState(false);
  const [schema, dispatchSchemaAction] = useReducer(
    validationSettingsReducer,
    {} as SchemaState,
  );

  const [businessSettings, dispatchBusinessSettings] = useReducer(
    businessSettingsReducer,
    {} as BusinessSettingsState,
  );

  const {
    getReceiptTypes,
    getSchema,
    getFieldAttributeTenantsByReceiptType,
    updateManyFieldAttributeTenant,
    getBusinessSettings,
    updateManyDocumentTypeBusinessValidationTenant,
    getDocumentTypeByReceiptType,
  } = useDataValidationService();

  const saveData = async () => {
    try {
      if (
        !currentReceiptType ||
        !schema.current.schema_field ||
        !selectedTenantId ||
        !currentDocumentType
      )
        return;

      const schemaInput = getCustomFieldAttributeTenants(
        schema.current.schema_field,
      );
      await updateManyFieldAttributeTenant(
        schemaInput,
        currentReceiptType?.id,
        selectedTenantId,
      );

      const businessSettingsInput = getCustomBusinessSettings(
        businessSettings.current,
      );
      await updateManyDocumentTypeBusinessValidationTenant(
        businessSettingsInput,
        currentDocumentType.document_type_id,
        selectedTenantId,
      );

      await initializeSettings(selectedTenantId, currentReceiptType?.id);

      openNotification({
        msj: t('settingsPage.updateSettingsSuccess'),
        type: 'success',
      });
    } catch (error) {
      openNotification({
        msj: t('settingsPage.updateSettingsError'),
        type: 'error',
      });
    }
  };

  const {
    UnsavedChangesModal,
    withUnsavedWarning,
    setShouldBlockAction,
    shouldBlockAction,
  } = useUnsavedChangesWarning({
    onSave: saveData,
  });

  const handleReceiptChange = (receiptType: IReceiptType) => {
    withUnsavedWarning(() => {
      setCurrentReceiptType(receiptType);
    });
  };

  const initializeReceiptTypes = async (selectedTenantId: number) => {
    try {
      if (!selectedTenantId) return;

      const receiptTypes: IReceiptType[] = await getReceiptTypes(
        selectedTenantId,
      );

      if (!receiptTypes) return;

      setReceiptTypes(receiptTypes);
      setCurrentReceiptType(receiptTypes[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const initializeSettings = async (
    selectedTenantId: number,
    receiptTypeId: number,
  ): Promise<void> => {
    try {
      const documentType = await getDocumentTypeByReceiptType(
        selectedTenantId,
        receiptTypeId,
      );

      const [schemas, fieldAttributeTenants, businessSettings]: [
        Schema[],
        FieldAttributeTenant[],
        BusinessValidation[],
      ] = await Promise.all([
        getSchema(selectedTenantId, receiptTypeId),
        getFieldAttributeTenantsByReceiptType(selectedTenantId, receiptTypeId),
        getBusinessSettings(selectedTenantId, documentType.document_type_id),
      ]);

      const schemaTree = getSchemaTree(
        schemas,
        fieldAttributeTenants,
        documentType.id,
      );

      if (!schemaTree) return;

      setCurrentDocumentType(documentType);
      dispatchSchemaAction({
        type: SchemaActionTypes.SET_SCHEMA,
        payload: {
          original: schemaTree as Schema,
          current: schemaTree as Schema,
        },
      });
      dispatchBusinessSettings({
        type: BusinessSettingsActionTypes.SET_BUSINESS_SETTINGS,
        payload: {
          original: businessSettings,
          current: businessSettings,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveButton = () => {
    setShouldSave(true);
  };

  const handleRestoreAllToDefault = () => {
    dispatchSchemaAction({
      type: SchemaActionTypes.RESET_ALL_FIELDS_TO_DEFAULT,
    });
    dispatchBusinessSettings({
      type: BusinessSettingsActionTypes.RESET_ALL_SETTINGS_TO_DEFAULT,
    });
    setShouldSave(true);

    setRestoreAllModalStatus(false);
  };

  const handleRestoreAllToPreviousState = () => {
    // TODO: despachar las acciones correspondientes para restablecer los datos
    dispatchSchemaAction({
      type: SchemaActionTypes.RESET_ALL_FIELDS_TO_PREVIOUS_STATE,
    });
    dispatchBusinessSettings({
      type: BusinessSettingsActionTypes.RESET_ALL_SETTINGS_TO_PREVIOUS_STATE,
    });
    setShouldSave(true);
    setRestoreAllModalStatus(false);
  };

  useEffect(() => {
    if (selectedTenantId) {
      initializeReceiptTypes(selectedTenantId);
    }
  }, []);

  useEffect(() => {
    if (selectedTenantId && currentReceiptType) {
      initializeSettings(selectedTenantId, currentReceiptType.id);
    }
  }, [currentReceiptType]);

  useEffect(() => {
    if (shouldSave) {
      saveData();
      setShouldSave(false);
    }
  }, [shouldSave]);

  useEffect(() => {
    if (
      JSON.stringify(businessSettings.current) !==
        JSON.stringify(businessSettings.original) ||
      JSON.stringify(schema.current) !== JSON.stringify(schema.original)
    ) {
      setShouldBlockAction(true);
    } else {
      setShouldBlockAction(false);
    }
  }, [schema, businessSettings, shouldBlockAction]);

  return (
    receiptTypes && (
      <>
        <div className="validation-settings">
          <SettingsHeader
            receiptTypes={receiptTypes}
            onReceiptChange={handleReceiptChange}
            onSave={handleSaveButton}
            setRestoreAllModalStatus={setRestoreAllModalStatus}
            currentReceiptType={currentReceiptType}
          />
          {/* <AutomationControl /> */}

          <BusinessSettingsTable
            businessSettings={businessSettings.current}
            dispatch={dispatchBusinessSettings}
          />

          <FieldSettingsTable
            schema={schema.current}
            dispatch={dispatchSchemaAction}
          />
        </div>
        <RestoreAllModal
          modalVisible={restoreAllModalStatus}
          setModalVisible={setRestoreAllModalStatus}
          onRestoreAllToDefault={handleRestoreAllToDefault}
          onRestoreAllToPreviousState={handleRestoreAllToPreviousState}
        />
        <UnsavedChangesModal />
      </>
    )
  );
};

export default ValidationSettings;
