export enum FieldAttributeTenantValue {
  TRUE = 'True',
  FALSE = 'False',
}

export enum SchemaActionTypes {
  SET_SCHEMA = 'SET_SCHEMA',
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  RESET_FIELD_TO_DEFAULT = 'RESET_FIELD_TO_DEFAULT',
  RESET_FIELD_TO_PREVIOUS_STATE = 'RESET_FIELD_TO_PREVIOUS_STATE',
  CONFIGURE_DATA_VALIDATION = 'CONFIGURE_DATA_VALIDATION',
  RESET_ALL_FIELDS_TO_DEFAULT = 'RESET_ALL_FIELDS_TO_DEFAULT',
  RESET_ALL_FIELDS_TO_PREVIOUS_STATE = 'RESET_ALL_FIELDS_TO_PREVIOUS_STATE',
}

export enum BusinessSettingsActionTypes {
  SET_BUSINESS_SETTINGS = 'SET_BUSINESS_SETTINGS',
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  RESET_SETTING_TO_DEFAULT = 'RESET_SETTING_TO_DEFAULT',
  RESET_SETTING_TO_PREVIOUS_STATE = 'RESET_SETTING_TO_PREVIOUS_STATE',
  RESET_ALL_SETTINGS_TO_DEFAULT = 'RESET_ALL_SETTINGS_TO_DEFAULT',
  RESET_ALL_SETTINGS_TO_PREVIOUS_STATE = 'RESET_ALL_SETTINGS_TO_PREVIOUS_STATE',
}
