import {
  BusinessValidation,
  UpdateBusinessValidationInput,
} from '../../../interfaces/BusinessValidation';
import { IReceiptType } from '../../../interfaces/Receipt';
import { ReceiptTypeDocumentType } from '../../../interfaces/ReceiptTypeDocumentType';
import {
  FieldAttributeTenant,
  Schema,
  UpdateFieldAttributeTenantInput,
} from '../../../interfaces/ValidationSchema';
import GraphqlService from '../../../services/graphql/GraphqlService';

const useDataValidationService = () => {
  const { Query, customRequest, Mutation } = GraphqlService();

  const getReceiptTypes = async (
    tenant_id: number,
  ): Promise<IReceiptType[]> => {
    return customRequest({
      query: Query.receipTypes,
      variables: {
        filter: {
          tenant_id,
          selectable: true,
          active: true,
        },
      },
    });
  };

  const getFieldAttributeTenantsByReceiptType = async (
    tenant_id: number,
    receipt_type_id: number,
  ): Promise<FieldAttributeTenant[]> => {
    return customRequest({
      query: Query.fieldAttributeTenantsByReceiptType,
      variables: {
        tenant_id,
        receipt_type_id,
      },
    });
  };

  const getSchema = async (
    tenant_id: number,
    receipt_type_id: number,
  ): Promise<Schema[]> => {
    return customRequest({
      query: Query.schemas,
      variables: {
        filter: {
          tenant_id,
          receipt_type_id,
        },
      },
    });
  };

  const getDocumentTypeByReceiptType = async (
    tenant_id: number,
    receipt_type_id: number,
  ): Promise<ReceiptTypeDocumentType> => {
    return customRequest({
      query: Query.receiptTypeDocumentTypeByReceiptType,
      variables: {
        tenant_id,
        receipt_type_id,
      },
    });
  };

  const getBusinessSettings = async (
    tenant_id: number,
    document_type_id: number,
  ): Promise<BusinessValidation[]> => {
    return customRequest({
      query: Query.documentTypeBusinessValidations,
      variables: {
        filter: {
          tenant_id,
          document_type_id,
        },
      },
    });
  };

  const updateManyFieldAttributeTenant = async (
    input: UpdateFieldAttributeTenantInput[],
    receipt_type_id: number,
    tenant_id: number,
  ) => {
    return customRequest({
      query: Mutation.updateManyFieldAttributeTenant,
      variables: {
        input,
        receipt_type_id,
        tenant_id,
      },
    });
  };

  const updateManyDocumentTypeBusinessValidationTenant = async (
    input: UpdateBusinessValidationInput[],
    document_type_id: number,
    tenant_id: number,
  ) => {
    return customRequest({
      query: Mutation.updateManyDocumentTypeBusinessValidationTenant,
      variables: {
        input,
        document_type_id,
        tenant_id,
      },
    });
  };

  return {
    getReceiptTypes,
    getFieldAttributeTenantsByReceiptType,
    getSchema,
    getBusinessSettings,
    getDocumentTypeByReceiptType,
    updateManyFieldAttributeTenant,
    updateManyDocumentTypeBusinessValidationTenant,
  };
};

export default useDataValidationService;
