import { LOGIN, LOGIN_FIREBASE } from '../query/User';
import {
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  UPDATE_USER,
  CREATE_USER,
  UPSERT_USER_ROLE,
  UPSERT_USER_FIREBASE,
  CREATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
  UPDATE_MY_PASSWORD,
  COMPLETE_REGISTER,
  RESEND_VALIDATE_TOKEN,
  CREATE_USER_PUBLIC,
  UPDATE_LAST_TENANT_ID_SELECTED,
  CREATE_USER_TENANT,
  DELETE_USER_TENANT,
} from './User';
import { UPDATE_SETTING } from './AppSetting';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from './Role';
import { UPSERT_ROLE_PERMISSION } from './RolePermission';
import { REFRESH_TOKEN } from './RefreshToken';
import { UPDATE_PERMISSION } from './Permission';
import {
  DISABLE_TWO_FACTOR_AUTH,
  GENERATE_TOTP_URL,
  TWO_FACTOR_AUTH,
} from './Totp2FA';
import { CREATE_SIGNATURE, DELETE_SIGNATURE } from './Signature';
import {
  CREATE_TENANT,
  DELETE_TENANT,
  SET_TENANT_STATUS_ACTIVO,
  UNSUBSCRIBE_TENANT,
  UPDATE_TENANT,
  UPDATE_TENANT_INTEGRATION,
  UPSERT_USERTENANT,
} from './Tenant';
import {
  CREATE_RECEIPT_DETAIL,
  DELETE_RECEIPT_DETAIL,
  UPDATE_RECEIPT_DETAIL,
  UPDATE_RECEIPT_DETAIL_BY_DECISION,
} from './ReceiptDetail';
import {
  CREATE_DECISION_ITEM,
  DELETE_DECISION_ITEM,
  IMPORT_PRODUCTS,
  UPDATE_DECISION_ITEM,
} from './DecisionItem';
import {
  CREATE_PENDING_RECEIPTS,
  DELETE_PENDING_RECEIPT,
} from './PendingReceipt';
import {
  CREATE_INTEGRATION_SETTING_TENANT,
  UPDATE_MANY_INTEGRATION_SETTING_TENANT,
} from './IntegrationSettingTenant';
import { CREATE_ORDER } from './Order';
import {
  REPROCESS_INCOMPLETE_RECEIPT_HEADERS,
  REMOVE_RECEIPT,
  SET_RECEIPT_HEADER_STATUS_DISCARDED,
  UPDATE_RECEIPT_HEADER,
  CREATE_RECEIPT_CONCEPT_EXTRACTED,
  UPDATE_RECEIPT_CONCEPT_EXTRACTED,
  DELETE_RECEIPT_CONCEPT_EXTRACTED,
  FINISH_REVIEW,
} from './Receipt';
import { CREATE_INVITATION, INVITATION_RESPONSE } from './Invitation';
import { CREATE_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT } from './Product';
import { CREATE_SEEN_TOUR } from './SeenTour';
import { UPDATE_EMAIL_CONFIGURATION } from './TenantEmailReceipt';
import {
  DELETE_MANY_REVIEW_RECEIPT_HEADER_FIELD,
  UPSERT_REVIEW_RECEIPT_HEADER_FIELD,
} from './ReviewReceiptHeaderField';
import {
  DELETE_MANY_REVIEW_RECEIPT_DETAIL_FIELD,
  UPSERT_REVIEW_RECEIPT_DETAIL_FIELD,
} from './ReviewReceiptDetailField';
import { UPDATE_TENANT_SETTING_VALUE } from './TenantSetting';
import {
  CREATE_CONCEPT_MASTER,
  CREATE_CONCEPT_RULE,
  DELETE_CONCEPT_MASTER,
  DELETE_CONCEPT_RULE,
  DELETE_MANY_CONCEPT_RULES,
  REMOVE_CONCEPT_MASTER_BY_ID,
  UPDATE_CONCEPT_MASTER,
  UPDATE_CONCEPT_RULE,
  UPDATE_MANY_CONCEPT_RULES,
} from './Concept';
import { UPSERT_NOTIFICATION_TYPES } from './UserNotificationType';
import { UPDATE_MANY_FIELD_ATTRIBUTE_TENANT } from './FieldAttributeTenant';
import { UPDATE_MANY_DOCUMENT_TYPE_BUSINESS_VALIDATION_TENANT } from './BusinessValidation';

const Mutation = {
  createRole: { name: 'createRole', gql: CREATE_ROLE },
  createUser: { name: 'createUser', gql: CREATE_USER },
  deleteRole: { name: 'deleteRole', gql: DELETE_ROLE },
  deleteUser: { name: 'deleteUser', gql: DELETE_USER },
  login: { name: 'login', gql: LOGIN },
  loginFirebase: { name: 'loginFirebase', gql: LOGIN_FIREBASE },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  setPassword: { name: 'setPassword', gql: SET_PASSWORD },
  updateAppSetting: { name: 'updateAppSetting', gql: UPDATE_SETTING },
  updatePermission: { name: 'updatePermission', gql: UPDATE_PERMISSION },
  updateRole: { name: 'updateRole', gql: UPDATE_ROLE },
  updateUser: { name: 'updateUser', gql: UPDATE_USER },
  upsertUserRole: { name: 'upsertUserRole', gql: UPSERT_USER_ROLE },
  upsertRolePermission: {
    name: 'upsertRolePermission',
    gql: UPSERT_ROLE_PERMISSION,
  },
  refreshToken: { name: 'refreshToken', gql: REFRESH_TOKEN },
  upsertUserFirebase: { name: 'upsertUserFirebase', gql: UPSERT_USER_FIREBASE },
  createProfilePicture: {
    name: 'createProfilePicture',
    gql: CREATE_PROFILE_PICTURE,
  },
  updateProfilePicture: {
    name: 'updateProfilePicture',
    gql: UPDATE_PROFILE_PICTURE,
  },
  deleteProfilePicture: {
    name: 'deleteProfilePicture',
    gql: DELETE_PROFILE_PICTURE,
  },
  updateMyPassword: {
    name: 'updateMyPassword',
    gql: UPDATE_MY_PASSWORD,
  },
  disableTwoFactorAuth: {
    name: 'disableTwoFactorAuth',
    gql: DISABLE_TWO_FACTOR_AUTH,
  },
  generateTotpUrl: {
    name: 'generateTotpUrl',
    gql: GENERATE_TOTP_URL,
  },
  twoFactorAuth: {
    name: 'twoFactorAuth',
    gql: TWO_FACTOR_AUTH,
  },
  createSignature: {
    name: 'createSignature',
    gql: CREATE_SIGNATURE,
  },
  deleteSignature: {
    name: 'deleteSignature',
    gql: DELETE_SIGNATURE,
  },
  upsertUserTenant: {
    name: 'upsertUserTenant',
    gql: UPSERT_USERTENANT,
  },
  createTenant: {
    name: 'createTenant',
    gql: CREATE_TENANT,
  },
  deleteTenants: {
    name: 'deleteTenants',
    gql: DELETE_TENANT,
  },
  updateTenant: {
    name: 'updateTenant',
    gql: UPDATE_TENANT,
  },
  completeRegister: {
    name: 'completeRegister',
    gql: COMPLETE_REGISTER,
  },
  resendValidateToken: {
    name: 'resendValidateToken',
    gql: RESEND_VALIDATE_TOKEN,
  },
  createUserPublic: {
    name: 'createUserPublic',
    gql: CREATE_USER_PUBLIC,
  },
  updateLastTenantIdSelected: {
    name: 'updateLastTenantIdSelected',
    gql: UPDATE_LAST_TENANT_ID_SELECTED,
  },
  updateReceiptDetail: {
    name: 'updateReceiptDetail',
    gql: UPDATE_RECEIPT_DETAIL,
  },
  updateReceiptDetailByDecision: {
    name: 'updateReceiptDetailByDecision',
    gql: UPDATE_RECEIPT_DETAIL_BY_DECISION,
  },
  createReceiptDetail: {
    name: 'createReceiptDetail',
    gql: CREATE_RECEIPT_DETAIL,
  },
  deleteReceiptDetail: {
    name: 'deleteReceiptDetail',
    gql: DELETE_RECEIPT_DETAIL,
  },
  createUserTenant: { name: 'createUserTenant', gql: CREATE_USER_TENANT },
  deleteUserTenant: { name: 'deleteUserTenant', gql: DELETE_USER_TENANT },
  createDecisionItem: {
    name: 'createDecisionItem',
    gql: CREATE_DECISION_ITEM,
  },
  updateDecisionItem: {
    name: 'updateDecisionItem',
    gql: UPDATE_DECISION_ITEM,
  },
  deleteDecisionItem: {
    name: 'deleteDecisionItem',
    gql: DELETE_DECISION_ITEM,
  },
  deletePendingReceipt: {
    name: 'deletePendingReceipt',
    gql: DELETE_PENDING_RECEIPT,
  },
  createPendingReceipts: {
    name: 'createPendingReceipts',
    gql: CREATE_PENDING_RECEIPTS,
  },
  updateTenantIntegration: {
    name: 'updateTenantIntegration',
    gql: UPDATE_TENANT_INTEGRATION,
  },
  unsubscribeTenant: {
    name: 'unsubscribeTenant',
    gql: UNSUBSCRIBE_TENANT,
  },
  createIntegrationSettingTenant: {
    name: 'createIntegrationSettingTenant',
    gql: CREATE_INTEGRATION_SETTING_TENANT,
  },
  updateManyIntegrationSettingTenant: {
    name: 'updateManyIntegrationSettingTenant',
    gql: UPDATE_MANY_INTEGRATION_SETTING_TENANT,
  },
  createOrder: {
    name: 'createOrder',
    gql: CREATE_ORDER,
  },
  reprocessIncompleteReceiptHeaders: {
    name: 'reprocessIncompleteReceiptHeaders',
    gql: REPROCESS_INCOMPLETE_RECEIPT_HEADERS,
  },
  removeReceipt: {
    name: 'removeReceipt',
    gql: REMOVE_RECEIPT,
  },
  setReceiptHeaderStatusDiscarded: {
    name: 'setReceiptHeaderStatusDiscarded',
    gql: SET_RECEIPT_HEADER_STATUS_DISCARDED,
  },
  setTenantStatusActivo: {
    name: 'setTenantStatusActivo',
    gql: SET_TENANT_STATUS_ACTIVO,
  },
  invitationResponse: {
    name: 'invitationResponse',
    gql: INVITATION_RESPONSE,
  },
  createInvitation: {
    name: 'createInvitation',
    gql: CREATE_INVITATION,
  },
  importProducts: {
    name: 'importProducts',
    gql: IMPORT_PRODUCTS,
  },
  createProduct: {
    name: 'createProduct',
    gql: CREATE_PRODUCT,
  },
  deleteProduct: {
    name: 'deleteProduct',
    gql: DELETE_PRODUCT,
  },
  updateProduct: {
    name: 'updateProduct',
    gql: UPDATE_PRODUCT,
  },
  createSeenTour: {
    name: 'createSeenTour',
    gql: CREATE_SEEN_TOUR,
  },
  updateReceiptHeader: {
    name: 'updateReceiptHeader',
    gql: UPDATE_RECEIPT_HEADER,
  },
  updateEmailConfiguration: {
    name: 'updateEmailConfiguration',
    gql: UPDATE_EMAIL_CONFIGURATION,
  },
  upsertReviewReceiptHeaderField: {
    name: 'upsertReviewReceiptHeaderField',
    gql: UPSERT_REVIEW_RECEIPT_HEADER_FIELD,
  },
  upsertReviewReceiptDetailField: {
    name: 'upsertReviewReceiptDetailField',
    gql: UPSERT_REVIEW_RECEIPT_DETAIL_FIELD,
  },
  deleteManyReviewReceiptHeaderField: {
    name: 'deleteManyReviewReceiptHeaderField',
    gql: DELETE_MANY_REVIEW_RECEIPT_HEADER_FIELD,
  },
  deleteManyReviewReceiptDetailField: {
    name: 'deleteManyReviewReceiptDetailField',
    gql: DELETE_MANY_REVIEW_RECEIPT_DETAIL_FIELD,
  },
  updateTenantSettingValue: {
    name: 'updateTenantSettingValue',
    gql: UPDATE_TENANT_SETTING_VALUE,
  },
  RemoveConceptMasterById: {
    name: 'RemoveConceptMasterById',
    gql: REMOVE_CONCEPT_MASTER_BY_ID,
  },
  createConceptMaster: {
    name: 'createConceptMaster',
    gql: CREATE_CONCEPT_MASTER,
  },
  updateConceptMaster: {
    name: 'updateConceptMaster',
    gql: UPDATE_CONCEPT_MASTER,
  },
  deleteConceptMaster: {
    name: 'deleteConceptMaster',
    gql: DELETE_CONCEPT_MASTER,
  },
  createConceptRule: {
    name: 'createConceptRule',
    gql: CREATE_CONCEPT_RULE,
  },
  deleteConceptRule: {
    name: 'deleteConceptRule',
    gql: DELETE_CONCEPT_RULE,
  },
  updateConceptRule: {
    name: 'updateConceptRule',
    gql: UPDATE_CONCEPT_RULE,
  },
  updateManyConceptRules: {
    name: 'updateManyConceptRules',
    gql: UPDATE_MANY_CONCEPT_RULES,
  },
  deleteManyConceptRules: {
    name: 'deleteManyConceptRules',
    gql: DELETE_MANY_CONCEPT_RULES,
  },
  createReceiptConceptExtracted: {
    name: 'createReceiptConceptExtracted',
    gql: CREATE_RECEIPT_CONCEPT_EXTRACTED,
  },
  updateReceiptConceptExtracted: {
    name: 'updateReceiptConceptExtracted',
    gql: UPDATE_RECEIPT_CONCEPT_EXTRACTED,
  },
  deleteReceiptConceptExtracted: {
    name: 'deleteReceiptConceptExtracted',
    gql: DELETE_RECEIPT_CONCEPT_EXTRACTED,
  },
  upsertUserNotificationType: {
    name: 'upsertUserNotificationType',
    gql: UPSERT_NOTIFICATION_TYPES,
  },
  finishReview: {
    name: 'finishReview',
    gql: FINISH_REVIEW,
  },
  updateManyFieldAttributeTenant: {
    name: 'updateManyFieldAttributeTenant',
    gql: UPDATE_MANY_FIELD_ATTRIBUTE_TENANT,
  },
  updateManyDocumentTypeBusinessValidationTenant: {
    name: 'updateManyDocumentTypeBusinessValidationTenant',
    gql: UPDATE_MANY_DOCUMENT_TYPE_BUSINESS_VALIDATION_TENANT,
  },
};

export { Mutation };
