import DefaultRedirectPage from '../pages/DefaultRedirect/DefaultRedirectPage';
import LoginPage from '../pages/Login/LoginPage';
import PublicLayout from '../layouts/publicLayout';
import RegisteredLayout from '../layouts/registered';
import AppSettingPage from '../pages/AppSetting/AppSettingPage';
import EmptyLayout from '../layouts/emptyLayout';
import ResetPasswordContentPage from '../pages/ResetPassword/ResetPasswordContentPage';
import SetPasswordContentPage from '../pages/SetPassword/SetPasswordPage';
import ProfilePage from '../pages/Profile/ProfilePage';
import { EnumsValues } from '../enums/EnumsValues';
import RolePage from '../pages/Role/RolePage';
import PermissionPage from '../pages/Permission/PermissionPage';
import UserPage from '../pages/User/UserPage';
import { IRouteConfig } from '../interfaces/routeConfig';
import AboutPage from '../pages/About/AboutPage';
import Generate2FA from '../pages/Totp2FA/Generate2FA/Generate2FA';
import Validate2FA from '../pages/Totp2FA/Validate2FA/Validate2FA';
import Config2FA from '../pages/Totp2FA/Config2FA/Config2FA';
import TenantPage from '../pages/Tenant/TenantPage';
import Receipt from '../pages/Receipt/Receipt';
import { CompleteRegisterPage } from '../pages/CompleteRegister/CompleteRegisterPage';
import { RegisterPage } from '../pages/Register/RegisterPage';
import { TenantEditPage } from '../pages/TenantEdit/TenantEditPage';
import { DataRelationPage } from '../pages/DataRelation/DataRelationPage';
import { Indicators } from '../pages/Indicators/Indicators';
import { PacksPage } from '../pages/PacksPage/PacksPage';
import { MethodsPage } from '../pages/Methods/MethodsPage';
import TwoFALayout from '../layouts/TwoFALayout/TwoFALayout';
import { WizardLayout } from '../layouts/WizardLayout/WizardLayout';
import { BillingFormPage } from '../pages/BillingFormPage/BillingFormPage';
import InvitationPage from '../pages/Invitation/Invitation';
import { TermsAndConditions } from '../pages/TermsAndConditions/TermsAndConditions';
import Product from '../pages/Product/Product';
import { SelectTenant } from '../pages/SelectTenant/SelectTenant';
import { ReviewReceipt } from '../pages/ReviewReceipt/ReviewReceipt';
import HomePage from '../pages/Home/HomePage';
import { Redirect } from 'react-router';
import { EmailReception } from '../pages/EmailReception/EmailReception';
import { TenantSetting } from '../pages/TenantSetting/TenantSetting';
import ReceiptLogs from '../pages/ReceiptLogs/ReceiptLogs';
import { ValidateEmailPage } from '../pages/ValidateEmail/ValidateEmailPage';
import { Concepts } from '../pages/Concepts/Concepts';
import { Functionalities } from '../pages/FunctionalitiesPage/Functionalities';
import ValidationSettings from '../pages/ValidationSetting/ValidationSettings';

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: DefaultRedirectPage,
    key: 'defaultRedirect',
  },
  {
    path: '/invitation',
    component: InvitationPage,
    key: 'invitation',
  },
  {
    path: '/termsandconditions',
    exact: true,
    component: TermsAndConditions,
    key: 'termsandconditions',
  },
  {
    path: '/wizard',
    name: 'Wizard',
    component: WizardLayout,
    key: 'wizard',
    routes: [
      {
        path: '/wizard/packs',
        component: PacksPage,
        name: 'Packs de créditos',
        key: 'packsPage',
      },
      {
        path: '/wizard/integration',
        component: MethodsPage,
        name: 'Integración',
        key: 'integrationPage',
      },
      {
        path: '/wizard/billingForm',
        component: BillingFormPage,
        name: 'Facturación',
        key: 'billingFormPage',
      },
      {
        path: '*',
        component: () => <Redirect to="/" />,
        key: 'defaultRedirectWizardLayout',
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/app',
    component: RegisteredLayout,
    key: 'app',
    routes: [
      {
        path: '/app/config2fa',
        component: Config2FA,
        key: 'config2fa',
        translation_key: 'page.config2fa',
        hideInMenu: true,
      },
      {
        path: '/app/home',
        component: HomePage,
        name: 'Inicio',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">home</span>
        ),
        key: 'inicio',
        translation_key: 'page.home',
        requiresTenantSelected: true,
      },
      {
        path: '/app/receipt',
        component: Receipt,
        name: 'Comprobantes',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            description
          </span>
        ),
        authority: EnumsValues.Functions.ListReceiptHeader,
        key: 'receipt',
        translation_key: 'page.receipt',
        requiresTenantSelected: true,
        className: 'receiptMenuPage',
      },
      {
        path: '/app/indicators',
        component: Indicators,
        name: 'Indicadores',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            bar_chart
          </span>
        ),
        authority: EnumsValues.Functions.GetReceiptHeaderToIndicator,
        key: 'indicators',
        translation_key: 'page.indicators',
        requiresTenantSelected: true,
        hideInMenu: true,
      },
      {
        path: '/app/packs',
        component: PacksPage,
        name: 'Créditos',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            monetization_on
          </span>
        ),
        featureFlag: EnumsValues.FeatureFlags.packsPage,
        key: 'packsPage',
        translation_key: 'page.packsPage',
        requiresTenantSelected: true,
        className: 'packsMenuPage',
      },
      {
        path: '/app/integration',
        name: 'Integración',
        component: EmptyLayout,
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            integration_instructions
          </span>
        ),
        key: 'integration',
        translation_key: 'page.integration',
        routes: [
          {
            path: '/app/integration/methods',
            component: MethodsPage,
            name: 'Métodos',
            key: 'methodsPage',
            featureFlag: EnumsValues.FeatureFlags.methodsPage,
            translation_key: 'page.methods',
            requiresTenantSelected: true,
          },
          {
            path: '/app/integration/product',
            component: Product,
            authority: EnumsValues.Functions.CreateProduct,
            name: 'Productos',
            key: 'productPage',
            translation_key: 'page.product',
            requiresTenantSelected: true,
          },
          {
            path: '/app/integration/concepts',
            component: Concepts,
            authority: EnumsValues.Functions.ReadConceptMaster,
            name: 'Conceptos',
            key: 'conceptsPage',
            translation_key: 'page.concepts',
            requiresTenantSelected: true,
          },
          {
            path: '/app/integration/data_relation',
            component: DataRelationPage,
            name: 'Relación de datos',
            authority: EnumsValues.Functions.ReadDecisionItem,
            key: 'dataRelationPage',
            translation_key: 'page.dataRelation',
            requiresTenantSelected: true,
          },
          {
            path: '*',
            component: () => <Redirect to="/" />,
            key: 'defaultRedirectEmptyLayoutIntegration',
            hideInMenu: true,
          },
        ],
      },
      {
        path: '/app/tenant',
        component: TenantEditPage,
        name: 'Organización',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">work</span>
        ),
        key: 'tenantEdit',
        translation_key: 'page.organization',
        requiresTenantSelected: true,
      },
      {
        path: '/app/reviewReceipt/:receipt_id',
        component: ReviewReceipt,
        key: 'reviewReceipt',
        requiresTenantSelected: true,
        hideInMenu: true,
      },
      {
        path: '/app/viewLogs',
        component: ReceiptLogs,
        key: 'receiptLogs',
        requiresTenantSelected: true,
        hideInMenu: true,
      },
      {
        path: '/app/administration',
        name: 'Administración',
        component: EmptyLayout,
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            instant_mix
          </span>
        ),
        authority: EnumsValues.Functions.AdministrationMenuRead,
        key: 'administration',
        translation_key: 'page.administration',
        routes: [
          {
            path: '/app/administration/role',
            component: RolePage,
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Roles',
            key: 'role',
            translation_key: 'page.role',
          },
          {
            path: '/app/administration/permission',
            component: PermissionPage,
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
            key: 'permission',
            translation_key: 'page.permission',
          },
          {
            path: '/app/administration/emailReception',
            component: EmailReception,
            authority: EnumsValues.Functions.GetTenantEmailReceipt,
            featureFlag: EnumsValues.FeatureFlags.emailReception,
            name: 'emailReception',
            key: 'emailReception',
            translation_key: 'page.emailReception',
          },
          {
            path: '/app/administration/TenantSetting',
            component: TenantSetting,
            authority: EnumsValues.Functions.GetTenantSetting,
            name: 'configurationTenant',
            key: 'configurationTenant',
            translation_key: 'page.configurationTenant',
          },
          {
            path: '/app/administration/tenant',
            component: TenantPage,
            authority: EnumsValues.Functions.AdministrationTenantRead,
            name: 'Cuentas comerciales',
            key: 'tenant',
            translation_key: 'page.tenant',
          },
          {
            path: '/app/administration/user',
            component: UserPage,
            authority: EnumsValues.Functions.UserList,
            name: 'Usuarios',
            key: 'user',
            translation_key: 'page.user',
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/functionalities',
            component: Functionalities,
            name: 'Funcionalidades',
            authority: EnumsValues.Functions.ReadFeatureFlag,
            key: 'functionalities',
            translation_key: 'page.functionalities',
            requiresTenantSelected: true,
            className: 'functionalitiesMenuPage',
          },
          {
            path: '/app/administration/validations',
            component: ValidationSettings,
            authority: EnumsValues.Functions.GetTenantSetting,
            name: 'validationSetting',
            key: 'validationSetting',
            translation_key: 'page.validationSettings',
          },
          {
            path: '*',
            component: () => <Redirect to="/" />,
            key: 'defaultRedirectEmptyLayoutAdministration',
            hideInMenu: true,
          },
        ],
      },
      {
        path: '/app/setting',
        authority: EnumsValues.Functions.AppSettingRead,
        component: AppSettingPage,
        name: 'Configuración',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            settings
          </span>
        ),
        key: 'setting',
        translation_key: 'page.setting',
      },
      {
        path: '/app/about',
        component: AboutPage,
        name: 'About',
        icon: (
          <span className="material-symbols-outlined sideMenu-icon">
            emoji_objects
          </span>
        ),
        key: 'about',
        translation_key: 'page.about',
      },
      {
        path: '/app/profile',
        // authority: EnumsValues.Functions.AppSettingRead,
        component: ProfilePage,
        name: 'Mi perfil',
        hideInMenu: true,
        key: 'profile',
        translation_key: 'page.profile',
      },
      {
        path: '/app/selectTenant',
        component: SelectTenant,
        hideInMenu: true,
        key: 'selectTenant',
      },
      {
        path: '*',
        component: () => <Redirect to="/" />,
        key: 'defaultRedirectRegisteredLayout',
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/2fa',
    component: TwoFALayout,
    key: 'twoFALayout',
    routes: [
      {
        path: '/2fa/generate',
        exact: true,
        component: Generate2FA,
        key: 'generate',
      },
      {
        path: '/2fa/validateTotp',
        exact: true,
        component: Validate2FA,
        key: 'validateTotp',
      },
      {
        path: '*',
        component: () => <Redirect to="/" />,
        key: 'defaultRedirectTwoFALayout',
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/',
    component: PublicLayout,
    key: 'publicLayout',
    routes: [
      {
        path: '/resetPassword',
        exact: true,
        component: ResetPasswordContentPage,
        key: 'resetPassword',
      },
      {
        exact: true,
        path: '/setPassword',
        component: SetPasswordContentPage,
        key: 'setPassword',
      },
      {
        exact: true,
        path: '/completeRegister',
        component: CompleteRegisterPage,
        key: 'completeRegister',
      },
      {
        exact: true,
        path: '/validateEmail',
        component: ValidateEmailPage,
        key: 'validateEmail',
      },
      {
        path: '/login',
        exact: true,
        component: LoginPage,
        key: 'login',
      },
      {
        path: '/register',
        exact: true,
        component: RegisterPage,
        key: 'register',
      },
      {
        path: '*',
        component: () => <Redirect to="/" />,
        key: 'defaultRedirectPublicLayout',
        hideInMenu: true,
      },
    ],
  },
];

export default routes;
