import { EnumsValues } from '../../../enums/EnumsValues';
import {
  FieldAttributeTenant,
  Schema,
  SchemaField,
} from '../../../interfaces/ValidationSchema';

const getFieldTenantAttributes = (
  fieldId: number,
  fieldAttributeTenants: FieldAttributeTenant[],
): FieldAttributeTenant[] => {
  return fieldAttributeTenants.filter(
    (attribute) => attribute.field_id === fieldId,
  );
};

const getSchemaFields = (
  schemaFields: SchemaField[],
  schemas: Schema[],
  fieldAttributeTenants: FieldAttributeTenant[],
): SchemaField[] => {
  return schemaFields.map((schema_field) => {
    if (schema_field.field) {
      if (schema_field.field.field_type.code !== 'object') {
        return {
          ...schema_field,
          field: {
            ...schema_field.field,

            field_attribute_tenant: getFieldTenantAttributes(
              schema_field.field.id,
              fieldAttributeTenants,
            ),
          },
        };
      } else {
        const schema = schemas.find(
          (schema) => schema.id === schema_field.field?.schema_id,
        );
        if (schema && schema.schema_field) {
          return {
            ...schema_field,
            schema_field: getSchemaFields(
              schema.schema_field,
              schemas,
              fieldAttributeTenants,
            ),
          };
        }

        return schema_field;
      }
    } else {
      return schema_field;
    }
  });
};

const fieldIdShouldBeRendered = (
  attributes: FieldAttributeTenant[],
): number[] => {
  return attributes
    .filter(
      (attribute) =>
        attribute.attribute_type.tags &&
        attribute.attribute_type.tags.includes(
          EnumsValues.FieldAttributeTags.REVIEWER_VISIBLE,
        ) &&
        attribute.value === 'True',
    )
    .map((attribute) => attribute.field_id);
};
const filterSchemaTree = (
  schemaFields: SchemaField[],
  fieldIdsToBeRendered: number[],
): SchemaField[] => {
  return schemaFields.filter((schemaField) => {
    if (schemaField.schema_field && schemaField.schema_field.length > 0) {
      schemaField.schema_field = filterSchemaTree(
        schemaField.schema_field,
        fieldIdsToBeRendered,
      );
      return schemaField.schema_field.length > 0;
    } else {
      return fieldIdsToBeRendered.includes(schemaField.field_id);
    }
  });
};

export const getSchemaTree = (
  schemas: Schema[],
  fieldAttributeTenants: FieldAttributeTenant[],
  documentTypeId: number,
): Schema | undefined => {
  const rootSchema = schemas.find(
    (schema) =>
      schema.document_type && schema.document_type[0]?.id === documentTypeId,
  );

  if (!rootSchema) return;

  // TODO: esto es temporal hasta que habilitemos el control de visibilidad
  const fieldIdsToBeRendered = fieldIdShouldBeRendered(fieldAttributeTenants);

  return {
    ...rootSchema,
    // TODO: Este wrapper filterSchemaTree es temporal hasta que habilitemos el control de visibilidad
    schema_field: filterSchemaTree(
      getSchemaFields(
        rootSchema!.schema_field!,
        schemas,
        fieldAttributeTenants,
      ),
      fieldIdsToBeRendered,
    ),
  };
};
